import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { ICreatePaymentIntentRequestModel } from '@models/request/create-payment-intent.request.model';
import { IPaymentIntentModel } from '@models/payment-intent.model';
import { PaginationHolder } from '@models/pagination.model';
import { RefundIntents } from '@app/modules/portal/pages/economy/pages/sales/components/refund-dialog/refund-dialog.component';
import { ITerminalTransactionResponseModel } from '@models/terminal-transaction-response.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  public createPaymentIntent(reqModel: ICreatePaymentIntentRequestModel, order_id: string): Observable<IPaymentIntentModel> {
    return this.http.post<IPaymentIntentModel>(`${environment.api_url}/orders/${order_id}/payment-intents`, reqModel);
  }

  public getPaymentIntents(order_id: string): Observable<PaginationHolder<IPaymentIntentModel>> {
    return this.http.get<PaginationHolder<IPaymentIntentModel>>(`${environment.api_url}/orders/${order_id}/payment-intents`);
  }

  public sendCustomerReceipt(order_id: string, receiptEmail: string): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/orders/${order_id}/send-receipt-by-email`, { email: receiptEmail });
  }

  public async createRefunds(id: string, intents: RefundIntents[]): Promise<any[]> {
    const completed = [];

    for (const intent of intents) {
      const body = await this.http
        .post<any>(`${environment.api_url}/orders/${id}/payment-intents/${intent.id}/refunds`, { amount: intent.amount })
        .toPromise();
      completed.push(body);
    }

    return completed;
  }

  public createTerminalTransaction(
    payment_intent_id: string,
    amount: number,
    terminal_id: string
  ): Observable<ITerminalTransactionResponseModel> {
    return this.http.post<any>(`${environment.api_url}/terminals/${terminal_id}/transactions`, {
      payment_intent_id,
      amount,
      reference: payment_intent_id,
    });
  }

  public cancelTerminalTransaction(terminal_id: string, transaction_id: string): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/terminals/${terminal_id}/transactions/${transaction_id}/cancel`, {});
  }
}
