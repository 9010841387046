import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { ICreateCategoryRequestModel } from '@models/request/create-category.request.model';
import { PaginationHolder } from '@models/pagination.model';
import { ICategoryModel } from '@models/category.model';
import { ICreateProductRequestModel, IProductModel, IUpdateProductRequestModel } from '@models/product.model';
import { IUpdateCategoryRequestModel } from '@models/request/update-category.request.model';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  constructor(private http: HttpClient) {}

  public getCategories(): Observable<PaginationHolder<ICategoryModel>> {
    return this.http.get<PaginationHolder<ICategoryModel>>(`${environment.api_url}/categories`);
  }

  public createCategory(reqModel: ICreateCategoryRequestModel, pagination: PaginationHolder<ICategoryModel>): Observable<any> {
    const category: ICreateCategoryRequestModel = {
      ...reqModel,
      index: pagination.meta.total_items + 1,
    };

    return this.http.post<any>(`${environment.api_url}/categories`, category);
  }

  public updateCategory(id: string, reqModel: IUpdateCategoryRequestModel): Observable<any> {
    return this.http.patch<any>(`${environment.api_url}/categories/${id}`, reqModel);
  }

  public deleteCategory(id: string): Observable<any> {
    return this.http.delete<any>(`${environment.api_url}/categories/${id}`);
  }

  public sortCategories(categories: { id: string; index: number }[]): Observable<any> {
    return this.http.patch<any>(`${environment.api_url}/categories/sorting`, categories);
  }

  public getProducts(product_type?: string): Observable<PaginationHolder<IProductModel>> {
    const params = product_type ? { product_type } : {};

    return this.http.get<PaginationHolder<IProductModel>>(`${environment.api_url}/products`, { params });
  }

  public getCategoryProducts(category_id: string): Observable<PaginationHolder<IProductModel>> {
    return this.http.get<PaginationHolder<IProductModel>>(`${environment.api_url}/categories/${category_id}/products`);
  }

  public createProduct(reqModel: ICreateProductRequestModel): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/products`, reqModel);
  }

  public updateProduct(id: string, reqModel: IUpdateProductRequestModel): Observable<any> {
    return this.http.patch<any>(`${environment.api_url}/products/${id}`, reqModel);
  }

  public deleteProduct(id: string): Observable<any> {
    return this.http.delete<any>(`${environment.api_url}/products/${id}`);
  }
}
