import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { IRootState } from '@app/core/store/root.state';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/store/authentication/authentication.service';
import {
  CreateAccount,
  CreateAccountSuccess,
  CreateUser,
  CreateUserSuccess,
  EAuthenticationActions,
  FetchAccount,
  FetchAccountSuccess,
  FetchUsers,
  FetchUsersSuccess,
  ForgotPassword,
  ForgotPasswordSuccess,
  Login,
  LoginSuccess,
  UpdateAccount,
  UpdateAccountSuccess,
  PreAuth,
  PreAuthSuccess,
  ResetPassword,
  ResetPasswordSuccess,
  UpdateUser,
  UpdateUserSuccess,
  UpdateUserWithImage,
  UpdateUserWithImageSuccess,
  Logout,
  SwitchAccount,
  SwitchAccountSuccess,
  FetchAccountSettings,
  FetchAccountSettingsSuccess,
  UpdateAccountSettings,
  UpdateAccountSettingsSuccess,
  LoginWithToken,
} from '@app/core/store/authentication/authentication.actions';
import { LoadError } from '@app/core/store/error/error.actions';
import { NavigateTo, SetIsAdminMode } from '@core/store/navigation/navigation.actions';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { ELocalStorageKeys } from '@enums/local-storage.enum';
import { FetchCategories, FetchProducts } from '@core/store/catalog/catalog.actions';
import { selectAccount, selectAuthUser } from '@core/store/authentication/authentication.selectors';
import { FetchBookingSettings } from '@core/store/booking/booking.actions';
import { LogContentService } from '@app/shared/components/log-content/services/log-content.service';
import { ELogLevel } from '@app/shared/components/log-content/enums/log-level.enum';
import { FetchFlatpayTerminals, FetchPaymentMethods } from '@core/store/economy/economy.actions';
import { ENotification } from '@enums/notification.enum';
import { UploadService } from '@core/store/upload/upload.service';
import { NotificationService } from '@services/notification/notification.service';
import { ClearHydration } from '@core/store/hydration/hydration.actions';
import { CredentialService } from '@services/auth/credential.service';
import { PusherService } from '@services/pusher/pusher.service';
import { EUserRole } from '@enums/user-permission.enum';
import { FetchBookingThemeSettings } from '@core/store/settings/settings.actions';
import { ListTills } from '@core/store/tills/tills.actions';

@Injectable()
export class AuthenticationEffects {
  constructor(
    private actions$: Actions,
    private store: Store<IRootState>,
    private authService: AuthenticationService,
    private localStorage: LocalStorageService,
    private logContentService: LogContentService,
    private uploadService: UploadService,
    private notificationService: NotificationService,
    private router: Router,
    private credentialService: CredentialService,
    private pusher: PusherService
  ) {}

  public onCreateAccount = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateAccount>(EAuthenticationActions.CreateAccount),
      mergeMap((action: CreateAccount) =>
        from(this.authService.createAccount(action.reqModel, action.captcha_token)).pipe(
          mergeMap(user => [
            new CreateAccountSuccess(user),
            new PreAuth(action.reqModel.user.email, action.reqModel.user.password, action.captcha_token),
          ]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onPreAuth = createEffect(() =>
    this.actions$.pipe(
      ofType<PreAuth>(EAuthenticationActions.PreAuth),
      mergeMap(action =>
        from(this.authService.preAuth(action.email, action.password, action.captcha_token)).pipe(
          mergeMap(accounts => {
            this.localStorage.clear();

            const actions: Action[] = [new ClearHydration(), new PreAuthSuccess(accounts)];

            if (accounts.length === 1) {
              actions.push(new Login(action.email, action.password, accounts[0]));
            } else {
              actions.push(new NavigateTo(['/select-chain']));
            }

            return actions;
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onLogin = createEffect(() =>
    this.actions$.pipe(
      ofType<Login>(EAuthenticationActions.Login),
      mergeMap(action =>
        from(this.authService.login(action.email, action.password, action.account)).pipe(
          mergeMap(user => {
            const isAdminMode = user.user.role === EUserRole.admin || user.user.role === EUserRole.owner;

            return [
              new LoginSuccess(user),
              new SetIsAdminMode(isAdminMode),
              new NavigateTo([isAdminMode ? '/portal/dashboard' : '/portal/bookings']),
            ];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onLoginWithToken = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginWithToken>(EAuthenticationActions.LoginWithToken),
      withLatestFrom(this.store.select(selectAuthUser)),
      mergeMap(([action, authUser]) =>
        from(this.authService.fetchUser(action.user_id)).pipe(
          mergeMap(user => {
            const isAdminMode = user.role === EUserRole.admin || user.role === EUserRole.owner;

            return from(this.authService.fetchAccount(action.account_id)).pipe(
              mergeMap(account => [
                new LoginSuccess({ ...account, user: { ...user, token: authUser.token } }),
                new SetIsAdminMode(isAdminMode),
                new NavigateTo([isAdminMode ? '/portal/dashboard' : '/portal/bookings']),
              ]),
              catchError(error => of(new LoadError(error, action)))
            );
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onSwitchAccount = createEffect(() =>
    this.actions$.pipe(
      ofType<SwitchAccount>(EAuthenticationActions.SwitchAccount),
      mergeMap(action =>
        from(this.authService.switchAccount(action.account.id)).pipe(
          mergeMap(user => [new SwitchAccountSuccess(), new LoginSuccess(user), new NavigateTo(['/portal/dashboard'])]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onLoginSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginSuccess>(EAuthenticationActions.LoginSuccess),
      mergeMap(action => {
        this.localStorage.setItem(ELocalStorageKeys.account, action.account);
        this.credentialService.clearCredentials();
        this.pusher.initPusher(action.account);

        return [
          new FetchAccount(),
          new FetchAccountSettings(),
          new FetchUsers(),
          new FetchBookingSettings(),
          new FetchCategories(),
          new FetchProducts(),
          new FetchPaymentMethods(),
          new FetchBookingThemeSettings(),
          new FetchFlatpayTerminals(),
          new ListTills(),
        ];
      })
    )
  );

  public onForgotPassword = createEffect(() =>
    this.actions$.pipe(
      ofType<ForgotPassword>(EAuthenticationActions.ForgotPassword),
      mergeMap(action =>
        from(this.authService.requestResetPassword(action.email)).pipe(
          mergeMap(() => [new ForgotPasswordSuccess()]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onResetPassword = createEffect(() =>
    this.actions$.pipe(
      ofType<ResetPassword>(EAuthenticationActions.ResetPassword),
      mergeMap(action =>
        from(this.authService.resetPassword(action.password, action.token)).pipe(
          mergeMap(() => [new ResetPasswordSuccess()]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchUsers = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchUsers>(EAuthenticationActions.FetchUsers),
      withLatestFrom(this.store.select(selectAccount)),
      mergeMap(([action, account]) =>
        from(this.authService.fetchUsers()).pipe(
          mergeMap(users => [new FetchUsersSuccess(users)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCreateUser = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateUser>(EAuthenticationActions.CreateUser),
      mergeMap(action =>
        from(this.authService.createUser(action.reqModel)).pipe(
          mergeMap(response => {
            this.logContentService.logContent({
              level: ELogLevel.SUCCESS,
              description: 'Bruger oprettet!',
              actions: [],
            });

            return [new CreateUserSuccess(), new FetchUsers()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUpdateUser = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateUser>(EAuthenticationActions.UpdateUser),
      mergeMap(action =>
        from(this.authService.patchUser(action.id, action.reqModel)).pipe(
          mergeMap(response => {
            this.logContentService.logContent({
              level: ELogLevel.SUCCESS,
              description: 'Bruger opdateret!',
              actions: [],
            });

            return [new UpdateUserSuccess(), new FetchUsers()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUpdateUserWithImage = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateUserWithImage>(EAuthenticationActions.UpdateUserWithImage),
      mergeMap(action =>
        this.uploadService.uploadImageWithSignedURL(action.file).pipe(
          mergeMap(image =>
            this.authService.patchUser(action.id, { ...action.reqModel, image_id: image.id }).pipe(
              mergeMap(() => {
                this.notificationService.sendNotification(ENotification.USER_UPDATED, null);

                return [new UpdateUserWithImageSuccess(), new FetchUsers()];
              }),
              catchError(error => of(new LoadError(error, action)))
            )
          ),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchAccount = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchAccount>(EAuthenticationActions.FetchAccount),
      withLatestFrom(this.store.select(selectAccount)),
      mergeMap(([action, account]) =>
        from(this.authService.fetchAccount(account.id)).pipe(
          mergeMap(response => [new FetchAccountSuccess(response)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchAccountSettings = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchAccountSettings>(EAuthenticationActions.FetchAccountSettings),
      mergeMap(action =>
        from(this.authService.fetchAccountSettings()).pipe(
          mergeMap(response => [new FetchAccountSettingsSuccess(response)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUpdateAccountSettings = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateAccountSettings>(EAuthenticationActions.UpdateAccountSettings),
      mergeMap(action =>
        from(this.authService.patchAccountSettings(action.reqData)).pipe(
          mergeMap(response => [new UpdateAccountSettingsSuccess(response)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUpdateAccount = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateAccount>(EAuthenticationActions.UpdateAccount),
      withLatestFrom(this.store.select(selectAccount)),
      mergeMap(([action, account]) =>
        from(this.authService.updateAccount(account.id, action.reqModel)).pipe(
          mergeMap(() => {
            this.logContentService.logContent({
              level: ELogLevel.SUCCESS,
              description: 'Konto opdateret!',
              actions: [],
            });

            return [new UpdateAccountSuccess(), new FetchAccount()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onLogout = createEffect(() =>
    this.actions$.pipe(
      ofType<Logout>(EAuthenticationActions.Logout),
      mergeMap(action => {
        this.localStorage.clear();

        return [new ClearHydration(), new NavigateTo(['/login'])];
      })
    )
  );
}
