import produce from 'immer';
import { EErrorActions, ErrorActions } from '@app/core/store/error/error.actions';
import { IEconomyState, initialEconomyState } from '@app/core/store/economy/economy.state';
import { EconomyActions, EEconomyActions } from '@app/core/store/economy/economy.actions';

export const economyReducers = (state: IEconomyState = initialEconomyState, action: EconomyActions | ErrorActions): IEconomyState => {
  let nextState;

  switch (action.type) {
    case EEconomyActions.FetchReport:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case EEconomyActions.FetchReportSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.report = action.report;
      });

      return nextState;

    // Fetch Sales

    case EEconomyActions.FetchSales:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case EEconomyActions.FetchSalesSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;

        if (action.sales.meta.current_page > 1) {
          if (!draftState.sales?.data) {
            draftState.sales = action.sales;
          } else {
            draftState.sales.data = draftState.sales.data.concat(action.sales.data);
            draftState.sales.meta = action.sales.meta;
          }
        } else {
          draftState.sales = action.sales;
        }
      });

      return nextState;

    // Fetch Sale By Id

    case EEconomyActions.FetchSaleById:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case EEconomyActions.FetchSaleByIdSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;

        const index = draftState.sales.data.findIndex(sale => sale.id === action.sale.id);

        if (index !== -1) {
          draftState.sales.data[index] = action.sale;
        } else {
          draftState.sales.data.push(action.sale);
        }
      });

      return nextState;

    // Fetch Payment Methods

    case EEconomyActions.FetchPaymentMethods:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case EEconomyActions.FetchPaymentMethodsSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.paymentMethods = action.paymentMethods;
      });

      return nextState;

    // Create/Edit Payment Method

    case EEconomyActions.CreatePaymentMethod:
    case EEconomyActions.EditPaymentMethod:
    case EEconomyActions.DeletePaymentMethod:
    case EEconomyActions.ActivateFlatpay:
    case EEconomyActions.DeactivateFlatpay:
    case EEconomyActions.FetchFlatpay:
    case EEconomyActions.FetchFlatpayTerminals:
    case EEconomyActions.DeleteFlatpayTerminal:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case EEconomyActions.CreatePaymentMethodSuccess:
    case EEconomyActions.EditPaymentMethodSuccess:
    case EEconomyActions.DeletePaymentMethodSuccess:
    case EEconomyActions.ActivateFlatpaySuccess:
    case EEconomyActions.DeactivateFlatpaySuccess:
    case EEconomyActions.DeleteFlatpayTerminalSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;

    case EEconomyActions.FetchFlatpaySuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.flatpay = action.flatpay;
      });

      return nextState;

    case EEconomyActions.FetchFlatpayTerminalsSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.terminals = action.terminals;
      });

      return nextState;

    // ERROR

    case EErrorActions.LoadError:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;
    default:
      return state;
  }
};
