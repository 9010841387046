import { IReportModel } from '@models/report.model';
import { PaginationHolder } from '@models/pagination.model';
import { IPaymentMethodModel } from '@models/payment-method.model';
import { ISaleModel } from '@models/sale.model';
import { IFlatpayIntegrationModel } from '@models/flatpay-integration.model';
import { IFlatpayTerminalModel } from '@models/flatpay-terminal.model';

export interface IEconomyState {
  isLoading: boolean;
  report?: IReportModel;
  paymentMethods?: PaginationHolder<IPaymentMethodModel>;
  sales?: PaginationHolder<ISaleModel>;
  flatpay?: IFlatpayIntegrationModel;
  terminals?: PaginationHolder<IFlatpayTerminalModel>;
}

export const initialEconomyState: IEconomyState = {
  isLoading: false,
};
