import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { PaginationHolder } from '@models/pagination.model';
import { ITillModel } from '@models/tills/till.model';
import { ITillSessionModel, ITillSessionRequestModel } from '@models/tills/till-session.model';
import { ITillExpectedAmountModel } from '@models/tills/till-expected-amount.model';

@Injectable({
  providedIn: 'root',
})
export class TillsService {
  constructor(private http: HttpClient) {}

  public getTills(): Observable<PaginationHolder<ITillModel>> {
    return this.http.get<PaginationHolder<ITillModel>>(`${environment.api_url}/tills`);
  }

  public getTillSessions(tillId: string, from_date?: string, to_date?: string): Observable<PaginationHolder<ITillSessionModel>> {
    return this.http.get<PaginationHolder<ITillSessionModel>>(`${environment.api_url}/tills/${tillId}/sessions`, {
      params: {
        from_date,
        to_date,
      },
    });
  }

  public getTillSessionById(tillId: string, sessionId: string): Observable<ITillSessionModel> {
    return this.http.get<ITillSessionModel>(`${environment.api_url}/tills/${tillId}/sessions/${sessionId}`);
  }

  public startTillSession(tillId: string, session: ITillSessionRequestModel): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/tills/${tillId}/sessions`, session);
  }

  public closeTillSession(tillId: string, sessionId: string, session: ITillSessionRequestModel): Observable<any> {
    return this.http.patch<any>(`${environment.api_url}/tills/${tillId}/sessions/${sessionId}`, session);
  }

  public fetchExpectedClosingAmounts(tillId: string, sessionId: string): Observable<ITillExpectedAmountModel[]> {
    return this.http.get<ITillExpectedAmountModel[]>(`${environment.api_url}/tills/${tillId}/sessions/${sessionId}/payment-methods`);
  }

  public createTill(name: string): Observable<ITillModel> {
    return this.http.post<ITillModel>(`${environment.api_url}/tills`, { name });
  }
}
