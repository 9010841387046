import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RootStoreModule } from '@core/store/root-store.module';
import { environment } from '@env/environment';
import { EEnvironment } from '@enums/environment.enum';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LogContentModule } from '@app/shared/components/log-content/log-content.module';
import { Store } from '@ngrx/store';
import { SecureGuard } from '@core/guards/secure.guard';
import { appInitializer } from '@app/app-initializer';
import { LanguageService } from '@services/language/language.service';
import { HttpInterceptor } from '@services/interceptor/http-interceptor.service';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomTranslateLoader } from '@services/language/translate-loader';
import { AuthGuard } from '@core/guards/auth.guard';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { LocalCurrencyModule } from '@app/shared/pipes/local-currency/local-currency.module';
import { PusherService } from '@services/pusher/pusher.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { TuiDialogModule, TuiRootModule } from '@taiga-ui/core';
import { TUI_DATE_FORMAT, TUI_DATE_SEPARATOR } from '@taiga-ui/cdk';
import { TUI_DANISH_LANGUAGE } from '../assets/i18n/tui-danish';
import { of } from 'rxjs';
import { TUI_LANGUAGE } from '@taiga-ui/i18n';
import { DropdownModule } from '@app/shared/components/dropdown/dropdown.module';
import { IfLetModule } from '@app/shared/directives/if-let/if-let.module';
import { UserAvatarModule } from '@app/shared/components/user-avatar/user-avatar.module';
import { CustomerFilterModule } from '@app/modules/portal/pages/customers/pipes/customer-filter.module';
import { SearchInputModule } from '@app/shared/components/search-input/search-input.module';
import { SentryErrorHandler } from '@core/utilities/sentry/sentry.handler';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { RoleGuard } from '@core/guards/role.guard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxStripeModule } from 'ngx-stripe';
import { BookingDurationPipe } from '@app/modules/portal/pages/bookings/components/new-booking-menu/pipes/booking-duration.pipe';
import { LocalDateModule } from '@app/shared/pipes/local-date/local-date.module';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { provideLottieOptions } from 'ngx-lottie';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    TuiRootModule,
    TuiDialogModule,
    HttpClientModule,
    AppRoutingModule,
    RootStoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
    }),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    environment.env !== EEnvironment.production ? StoreDevtoolsModule.instrument() : [],
    LogContentModule,
    LocalCurrencyModule,
    OverlayModule,
    DropdownModule,
    IfLetModule,
    UserAvatarModule,
    CustomerFilterModule,
    SearchInputModule,
    NgxStripeModule.forRoot(environment.stripe),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    BookingDurationPipe,
    LocalDateModule,
    NgOptimizedImage,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [LocalStorageService, Store, PusherService, Sentry.TraceService],
    },
    SecureGuard,
    AuthGuard,
    RoleGuard,
    TranslateService,
    provideLottieOptions({ player: () => import('lottie-web') }),
    environment.env !== EEnvironment.development ? { provide: ErrorHandler, useClass: SentryErrorHandler } : [],
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.captcha },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true },
    { provide: TUI_DATE_FORMAT, useValue: 'DMY' },
    { provide: TUI_DATE_SEPARATOR, useValue: '/' },
    { provide: TUI_LANGUAGE, useValue: of(TUI_DANISH_LANGUAGE) },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function getLocale(interService: LanguageService): string {
  return interService.getCurrentLanguage().angularKey;
}
