import produce from 'immer';
import { EErrorActions, ErrorActions } from '@app/core/store/error/error.actions';
import { ISettingsState, initialSettingsState } from '@app/core/store/settings/settings.state';
import { SettingsActions, ESettingsActions } from '@app/core/store/settings/settings.actions';

export const settingsReducers = (state: ISettingsState = initialSettingsState, action: SettingsActions | ErrorActions): ISettingsState => {
  let nextState;

  switch (action.type) {
    case ESettingsActions.FetchBookingHours:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case ESettingsActions.FetchBookingHoursSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.booking_hours = action.hours;
      });

      return nextState;

    case ESettingsActions.ChangeBookingHours:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case ESettingsActions.ChangeBookingHoursSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;

    case ESettingsActions.FetchSchedule:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case ESettingsActions.FetchScheduleSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.schedule = action.schedule;
      });

      return nextState;

    case ESettingsActions.PatchSchedule:
    case ESettingsActions.CreateSchedule:
    case ESettingsActions.DeleteSchedule:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case ESettingsActions.PatchScheduleSuccess:
    case ESettingsActions.CreateScheduleSuccess:
    case ESettingsActions.DeleteScheduleSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;

    // Fetch Booking Theme Settings

    case ESettingsActions.FetchBookingThemeSettings:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case ESettingsActions.FetchBookingThemeSettingsSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.bookingThemeSettings = action.settings;
      });

      return nextState;

    // Fetch Booking Theme Settings

    case ESettingsActions.UpdateBookingThemeSettings:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
        draftState.bookingThemeSettings = action.settings;
      });

      return nextState;
    case ESettingsActions.UpdateBookingThemeSettingsSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;

    // Fetch SMS Settings

    case ESettingsActions.FetchSMSSettings:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case ESettingsActions.FetchSMSSettingsSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.smsSettings = action.settings;
      });

      return nextState;

    // Fetch SMS Templates

    case ESettingsActions.FetchSMSTemplates:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case ESettingsActions.FetchSMSTemplatesSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.smsTemplates = action.templates;
      });

      return nextState;

    // Update SMS Template

    case ESettingsActions.UpdateSMSTemplate:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case ESettingsActions.UpdateSMSTemplateSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;

    // Set Sidebar Visibility

    case ESettingsActions.SetSidebarVisibility:
      nextState = produce(state, draftState => {
        draftState.isSidebarVisible = action.isVisible;
      });

      return nextState;

    case ESettingsActions.SetCalendarScale:
      nextState = produce(state, draftState => {
        const newZoom = action.direction === 'increase' ? draftState.scale + 0.4 : draftState.scale - 0.4;
        draftState.scale = Math.min(5, Math.max(1, newZoom));
      });

      return nextState;

    // ERROR

    case EErrorActions.LoadError:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;
    default:
      return state;
  }
};
