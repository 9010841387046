import produce from 'immer';
import { EErrorActions, ErrorActions } from '@app/core/store/error/error.actions';
import { IOrderState, initialOrderState } from '@app/core/store/order/order.state';
import { OrderActions, EOrderActions } from '@app/core/store/order/order.actions';

export const orderReducers = (state: IOrderState = initialOrderState, action: OrderActions | ErrorActions): IOrderState => {
  let nextState;

  switch (action.type) {
    case EOrderActions.FetchOrder:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case EOrderActions.FetchOrderSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.order = action.order;
      });

      return nextState;

    // Create Order

    case EOrderActions.CreateOrder:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case EOrderActions.CreateOrderSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.order = action.order;
      });

      return nextState;

    // Update Orderlines

    case EOrderActions.UpdateOrderLines:
    case EOrderActions.UpdateOrderLine:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case EOrderActions.UpdateOrderLinesSuccess:
    case EOrderActions.UpdateOrderLineSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;

    // Add Discount

    case EOrderActions.AddDiscount:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case EOrderActions.AddDiscountSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;

    // Clear Order

    case EOrderActions.ClearOrder:
      nextState = produce(state, draftState => {
        draftState.order = undefined;
      });

      return nextState;

    // ERROR

    case EErrorActions.LoadError:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;
    default:
      return state;
  }
};
