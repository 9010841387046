import { PaginationHolder } from '@models/pagination.model';
import { IBookingHourModel } from '@models/booking-hour.model';
import { ISMSSettingsModel } from '@models/sms-settings.model';
import { ISMSTemplateModel } from '@models/sms-template.model';
import { IBookingThemeSettings } from '@models/booking-theme-settings.model';
import { IScheduleModel } from '@models/schedule.model';

export interface ISettingsState {
  isLoading: boolean;
  isSidebarVisible: boolean;
  booking_hours?: PaginationHolder<IBookingHourModel>;
  smsSettings?: ISMSSettingsModel;
  smsTemplates?: PaginationHolder<ISMSTemplateModel>;
  bookingThemeSettings?: IBookingThemeSettings;
  schedule?: IScheduleModel[];
  scale: number;
}

export const initialSettingsState: ISettingsState = {
  isLoading: false,
  isSidebarVisible: true,
  scale: 3,
};
