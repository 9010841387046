import { RouterReducerState } from '@ngrx/router-store';
import { IAuthenticationState, initialAuthenticationState } from '@app/core/store/authentication/authentication.state';
import { INavigationState, initialNavigationState } from '@core/store/navigation/navigation.state';
import { IBookingState, initialBookingState } from '@core/store/booking/booking.state';
import { ICatalogState, initialCatalogState } from '@core/store/catalog/catalog.state';
import { initialUploadState, IUploadState } from '@core/store/upload/upload.state';
import { initialOrderState, IOrderState } from '@core/store/order/order.state';
import { initialPaymentState, IPaymentState } from '@core/store/payment/payment.state';
import { initialSettingsState, ISettingsState } from '@core/store/settings/settings.state';
import { ICustomerState, initialCustomerState } from '@core/store/customer/customer.state';
import { IEconomyState, initialEconomyState } from '@core/store/economy/economy.state';
import { initialStatisticsState, IStatisticsState } from '@core/store/statistics/statistics.state';
import { initialVoucherState, IVoucherState } from '@core/store/voucher/voucher.state';
import { IBillingState, initialBillingState } from '@core/store/billing/billing.state';
import { initialTillState, ITillState } from '@core/store/tills/tills.state';

export interface IRootState {
  router?: RouterReducerState;
  navigation: INavigationState;
  authentication: IAuthenticationState;
  booking: IBookingState;
  catalog: ICatalogState;
  upload: IUploadState;
  order: IOrderState;
  payment: IPaymentState;
  settings: ISettingsState;
  customer: ICustomerState;
  economy: IEconomyState;
  statistics: IStatisticsState;
  voucher: IVoucherState;
  billing: IBillingState;
  tills: ITillState;
}

export const initialAppState: IRootState = {
  navigation: initialNavigationState,
  authentication: initialAuthenticationState,
  booking: initialBookingState,
  catalog: initialCatalogState,
  upload: initialUploadState,
  order: initialOrderState,
  payment: initialPaymentState,
  settings: initialSettingsState,
  customer: initialCustomerState,
  economy: initialEconomyState,
  statistics: initialStatisticsState,
  voucher: initialVoucherState,
  billing: initialBillingState,
  tills: initialTillState,
};

export function getInitialState(): IRootState {
  return initialAppState;
}
