import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TillsEffects } from '@core/store/tills/tills.effects';
import { tillsReducers } from '@core/store/tills/tills.reducers';

@NgModule({
  imports: [StoreModule.forFeature('tills', tillsReducers), EffectsModule.forFeature([TillsEffects])],
  providers: [TillsEffects],
})
export class TillsStoreModule {}
