import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginationHolder } from '@models/pagination.model';
import { environment } from '@env/environment';
import { IBookingHourModel } from '@models/booking-hour.model';
import { IUpdateSmsSettingsRequestModel } from '@models/request/update-sms-settings.request.model';
import { ISMSTemplateModel } from '@models/sms-template.model';
import { IUpdateSMSTemplateRequestModel } from '@models/request/update-sms-template.request.model';
import { IBookingThemeSettings } from '@models/booking-theme-settings.model';
import { IScheduleModel, IScheduleUserWorkingHourModelCreate, IScheduleUserWorkingHourModelPatch } from '@models/schedule.model';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private http: HttpClient) {}

  public getBookingHours(): Observable<PaginationHolder<IBookingHourModel>> {
    return this.http.get<PaginationHolder<IBookingHourModel>>(`${environment.api_url}/booking-hours`);
  }

  public async postBookingHours(toCreate: IBookingHourModel[]): Promise<any[]> {
    const completed = [];

    for (const bookingHour of toCreate) {
      const body = await this.http.post<IBookingHourModel>(`${environment.api_url}/booking-hours`, bookingHour).toPromise();
      completed.push(body);
    }

    return completed;
  }

  public async patchBookingHours(toUpdate: IBookingHourModel[]): Promise<any[]> {
    const completed = [];

    for (const bookingHour of toUpdate) {
      const body = await this.http
        .patch<IBookingHourModel>(`${environment.api_url}/booking-hours/${bookingHour.id}`, bookingHour)
        .toPromise();
      completed.push(body);
    }

    return completed;
  }

  public getSchedule(from_date: string, to_date: string): Observable<IScheduleModel[]> {
    return this.http.get<any>(`${environment.api_url}/user-working-hours/schedule`, { params: { from_date, to_date } });
  }

  public postSchedule(reqData: IScheduleUserWorkingHourModelCreate): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/user-working-hours`, reqData);
  }

  public patchSchedule(id: string, reqData: IScheduleUserWorkingHourModelPatch): Observable<any> {
    return this.http.patch<any>(`${environment.api_url}/user-working-hours/${id}`, reqData);
  }

  public deleteSchedule(id: string): Observable<any> {
    return this.http.delete<any>(`${environment.api_url}/user-working-hours/${id}`);
  }

  public getBookingThemeSettings(): Observable<IBookingThemeSettings> {
    return this.http.get<any>(`${environment.api_url}/booking-theme-settings`);
  }

  public patchBookingThemeSettings(reqData: IBookingThemeSettings): Observable<any> {
    return this.http.patch<any>(`${environment.api_url}/booking-theme-settings`, reqData);
  }

  public async deleteBookingHours(toDelete: IBookingHourModel[]): Promise<any> {
    const completed = [];

    for (const bookingHour of toDelete) {
      const body = await this.http.delete<IBookingHourModel>(`${environment.api_url}/booking-hours/${bookingHour.id}`).toPromise();
      completed.push(body);
    }

    return completed;
  }

  public getSMSSettings(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}/sms/settings`);
  }

  public getSMSPrice(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}/billing/current-sms-price`);
  }

  public patchSMSSettings(reqData: IUpdateSmsSettingsRequestModel): Observable<any> {
    return this.http.patch<any>(`${environment.api_url}/sms/settings`, reqData);
  }

  public getSMSTemplates(): Observable<PaginationHolder<ISMSTemplateModel>> {
    return this.http.get<PaginationHolder<ISMSTemplateModel>>(`${environment.api_url}/sms-templates`);
  }

  public patchSMSTemplates(id: string, reqData: IUpdateSMSTemplateRequestModel): Observable<any> {
    return this.http.patch<any>(`${environment.api_url}/sms-templates/${id}`, reqData);
  }
}
