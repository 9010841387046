import { PaginationHolder } from '@models/pagination.model';
import { ITillModel } from '@models/tills/till.model';
import { ITillSessionModel } from '@models/tills/till-session.model';
import { ITillExpectedAmountModel } from '@models/tills/till-expected-amount.model';

export interface ITillState {
  isLoading: boolean;
  tills?: PaginationHolder<ITillModel>;
  tillSessions?: PaginationHolder<ITillSessionModel>;
  lastTillSession?: ITillSessionModel;
  expectedClosingAmounts?: ITillExpectedAmountModel[];
  selectedTillRange?: {
    from: string;
    to: string;
  };
}

export const initialTillState: ITillState = {
  isLoading: false,
};
