import { Injectable } from '@angular/core';
import moment from 'moment';
import { ELocalStorageKeys } from "@app/core/enums/local-storage.enum";
import { ILocalStorageItem } from "@app/core/models/local-storage.model";

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	constructor() {}

	public setItem(localStorageKey: ELocalStorageKeys, value: any, expireDate?: Date): void {
		const item: ILocalStorageItem = this.createExpiryItem(value, expireDate);
		localStorage.setItem(localStorageKey, JSON.stringify(item));
	}

	public getItem(localStorageKey: ELocalStorageKeys): any {
		const item: string | null = localStorage.getItem(localStorageKey);

		if (!item) {
			return null;
		}

		const parsedItem: ILocalStorageItem = JSON.parse(item);
		const now: Date = new Date();

		if (now.getTime() > parsedItem.expiry) {
			this.removeItem(parsedItem.value);

			return null;
		}

		return parsedItem.value;
	}

	public removeItem(localStorageKey: ELocalStorageKeys): void {
		localStorage.removeItem(localStorageKey);
	}

  public clear(): void {
    localStorage.clear();
  }

	private createExpiryItem(value: any, expireDate?: Date): ILocalStorageItem {
		const defaultExpireDate: Date = moment(new Date()).add(7, 'days').toDate();

		return {
			value,
			expiry: expireDate?.getTime() ?? defaultExpireDate.getTime(),
		};
	}
}
