import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import { distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";
import { EHydrateActions, Hydrate, HydrateFailure, HydrateSuccess } from './hydration.actions';
import { IRootState } from '@app/core/store/root.state';
import { LocalStorageService } from '@app/core/services/local-storage/local-storage.service';
import moment from 'moment';
import { ELocalStorageKeys } from "@app/core/enums/local-storage.enum";

@Injectable()
export class HydrationEffects {
	constructor(
		private actions$: Actions,
		private store: Store<IRootState>,
		private localStorageService: LocalStorageService
	) {}

	public onHydrate$ = createEffect(() => this.actions$.pipe(ofType<Hydrate>(EHydrateActions.Hydrate),
    map(() => {
      const storageValue = this.localStorageService.getItem(ELocalStorageKeys.STATE);

      if (storageValue) {
        try {
          const state: IRootState = storageValue;
          state.router = null;

          return new HydrateSuccess({...state});
        } catch {
          this.localStorageService.removeItem(ELocalStorageKeys.STATE);
        }
      }

      return new HydrateFailure();
    })
  ));

	public onHydrationChange$ = createEffect(() => this.actions$.pipe(
			ofType(EHydrateActions.HydrateSuccess, EHydrateActions.HydrateFailure),
			switchMap(() => this.store),
			distinctUntilChanged(),
			tap((state: IRootState) => {
				const expireInThirtyDays: Date = moment(new Date()).add(30, 'days').toDate();
				this.localStorageService.setItem(ELocalStorageKeys.STATE, state, expireInThirtyDays);
			})
		), { dispatch: false }
	);
}
