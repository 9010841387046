import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { LoadError } from '@core/store/error/error.actions';
import {
  CancelTerminalTransaction,
  CancelTerminalTransactionSuccess,
  CreatePaymentIntent,
  CreatePaymentIntentSuccess,
  CreateTerminalTransaction,
  CreateTerminalTransactionSuccess,
  EPaymentActions,
  FetchPaymentIntents,
  FetchPaymentIntentsSuccess,
  RefundPayment,
  RefundPaymentSuccess,
  SendCustomerReceipt,
  SendCustomerReceiptSuccess,
} from '@core/store/payment/payment.actions';
import { PaymentService } from '@core/store/payment/payment.service';
import { FetchOrder } from '@core/store/order/order.actions';
import { FetchSaleById } from '@core/store/economy/economy.actions';
import { NotificationService } from '@services/notification/notification.service';
import { ENotification } from '@enums/notification.enum';

@Injectable()
export class PaymentEffects {
  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private paymentService: PaymentService
  ) {}

  public onCreatePaymentIntent = createEffect(() =>
    this.actions$.pipe(
      ofType<CreatePaymentIntent>(EPaymentActions.CreatePaymentIntent),
      mergeMap(action =>
        from(this.paymentService.createPaymentIntent(action.reqModel, action.order_id)).pipe(
          mergeMap(intent => {
            this.notificationService.sendNotification(ENotification.PAYMENT_INTENT_CREATED, intent);

            const actions: Action[] = [new FetchOrder(action.order_id), new CreatePaymentIntentSuccess()];

            if (action.receiptEmail?.length > 0) {
              actions.push(new SendCustomerReceipt(action.order_id, action.receiptEmail));
            }

            return actions;
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchPaymentIntents = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchPaymentIntents>(EPaymentActions.FetchPaymentIntents),
      mergeMap(action =>
        from(this.paymentService.getPaymentIntents(action.order_id)).pipe(
          mergeMap(data => [new FetchPaymentIntentsSuccess(data)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onSendCustomerReceipt = createEffect(() =>
    this.actions$.pipe(
      ofType<SendCustomerReceipt>(EPaymentActions.SendCustomerReceipt),
      mergeMap(action =>
        from(this.paymentService.sendCustomerReceipt(action.order_id, action.receiptEmail)).pipe(
          mergeMap(() => [new SendCustomerReceiptSuccess()]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onRefundPayment = createEffect(() =>
    this.actions$.pipe(
      ofType<RefundPayment>(EPaymentActions.RefundPayment),
      mergeMap(action =>
        from(this.paymentService.createRefunds(action.id, action.intents)).pipe(
          mergeMap(() => [new RefundPaymentSuccess(), new FetchSaleById(action.id), new FetchPaymentIntents(action.id)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCreateTerminalTransaction = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateTerminalTransaction>(EPaymentActions.CreateTerminalTransaction),
      mergeMap(action =>
        from(this.paymentService.createTerminalTransaction(action.payment_intent_id, action.amount, action.terminal_id)).pipe(
          mergeMap(transaction => {
            this.notificationService.sendNotification(ENotification.TERMINAL_TRANSACTION_CREATED, transaction);

            return [new CreateTerminalTransactionSuccess()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCancelTerminalTransaction = createEffect(() =>
    this.actions$.pipe(
      ofType<CancelTerminalTransaction>(EPaymentActions.CancelTerminalTransaction),
      mergeMap(action =>
        from(this.paymentService.cancelTerminalTransaction(action.terminal_id, action.transaction_id)).pipe(
          mergeMap(() => [new CancelTerminalTransactionSuccess()]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );
}
