import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { LoadError } from '@core/store/error/error.actions';
import { TillsService } from '@core/store/tills/tills.service';
import {
  CloseTillSession,
  CloseTillSessionSuccess,
  CreateTill,
  CreateTillSuccess,
  ETillActions,
  FetchExpectedClosingAmounts,
  FetchExpectedClosingAmountsSuccess,
  GetTillSessionById,
  GetTillSessionByIdSuccess,
  ListTills,
  ListTillSessions,
  ListTillSessionsSuccess,
  ListTillsSuccess,
  StartTillSession,
  StartTillSessionSuccess,
} from '@core/store/tills/tills.actions';
import { NotificationService } from '@services/notification/notification.service';
import { ENotification } from '@enums/notification.enum';
import { Store } from '@ngrx/store';
import { IRootState } from '@core/store/root.state';
import { selectSelectedTillRange } from '@core/store/tills/tills.selectors';

@Injectable()
export class TillsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<IRootState>,
    private tillsService: TillsService,
    private notifications: NotificationService
  ) {}

  public onListTills = createEffect(() =>
    this.actions$.pipe(
      ofType<ListTills>(ETillActions.ListTills),
      mergeMap(action =>
        from(this.tillsService.getTills()).pipe(
          mergeMap(data => [new ListTillsSuccess(data)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onListTillSessions = createEffect(() =>
    this.actions$.pipe(
      ofType<ListTillSessions>(ETillActions.ListTillSessions),
      withLatestFrom(this.store.select(selectSelectedTillRange)),
      mergeMap(([action, tillRange]) =>
        from(this.tillsService.getTillSessions(action.tillId, tillRange.from, tillRange.to)).pipe(
          mergeMap(data => [new ListTillSessionsSuccess(data)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onGetTillSessionById = createEffect(() =>
    this.actions$.pipe(
      ofType<GetTillSessionById>(ETillActions.GetTillSessionById),
      mergeMap(action =>
        from(this.tillsService.getTillSessionById(action.tillId, action.sessionId)).pipe(
          mergeMap(data => [new GetTillSessionByIdSuccess(data)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onStartTillSession = createEffect(() =>
    this.actions$.pipe(
      ofType<StartTillSession>(ETillActions.StartTillSession),
      mergeMap(action =>
        from(this.tillsService.startTillSession(action.tillId, action.session)).pipe(
          mergeMap(() => [new StartTillSessionSuccess(), new ListTills(), new ListTillSessions(action.tillId)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCloseTillSession = createEffect(() =>
    this.actions$.pipe(
      ofType<CloseTillSession>(ETillActions.CloseTillSession),
      mergeMap(action =>
        from(this.tillsService.closeTillSession(action.tillId, action.sessionId, action.session)).pipe(
          mergeMap(() => [new CloseTillSessionSuccess(), new ListTills(), new ListTillSessions(action.tillId)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchExpectedClosingAmounts = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchExpectedClosingAmounts>(ETillActions.FetchExpectedClosingAmounts),
      mergeMap(action =>
        from(this.tillsService.fetchExpectedClosingAmounts(action.tillId, action.sessionId)).pipe(
          mergeMap(data => [new FetchExpectedClosingAmountsSuccess(data)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCreateTill = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateTill>(ETillActions.CreateTill),
      mergeMap(action =>
        from(this.tillsService.createTill(action.name)).pipe(
          mergeMap(() => {
            this.notifications.sendNotification(ENotification.CASH_COUNTING_UPDATED, null);

            return [new CreateTillSuccess(), new ListTills()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );
}
