import produce from 'immer';
import { EErrorActions, ErrorActions } from '@app/core/store/error/error.actions';
import { initialTillState, ITillState } from '@core/store/tills/tills.state';
import { ETillActions, TillActions } from '@core/store/tills/tills.actions';

export const tillsReducers = (state: ITillState = initialTillState, action: TillActions | ErrorActions): ITillState => {
  let nextState: ITillState;

  switch (action.type) {
    case ETillActions.ListTills:
    case ETillActions.ListTillSessions:
    case ETillActions.GetTillSessionById:
    case ETillActions.StartTillSession:
    case ETillActions.CloseTillSession:
    case ETillActions.FetchExpectedClosingAmounts:
    case ETillActions.CreateTill:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;

    case ETillActions.ListTillsSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.tills = action.tills;
      });

      return nextState;

    case ETillActions.ListTillSessionsSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.tillSessions = action.tillSessions;
      });

      return nextState;

    case ETillActions.GetTillSessionByIdSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.lastTillSession = action.tillSession;
      });

      return nextState;

    case ETillActions.StartTillSessionSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;

    case ETillActions.CloseTillSessionSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;

    case ETillActions.FetchExpectedClosingAmountsSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.expectedClosingAmounts = action.expectedClosingAmounts;
      });

      return nextState;

    case ETillActions.CreateTillSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;

    case ETillActions.SetSelectedTillRange:
      nextState = produce(state, draftState => {
        draftState.selectedTillRange = {
          from: action.from,
          to: action.to,
        };
      });

      return nextState;

    // ERROR

    case EErrorActions.LoadError:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;
    default:
      return state;
  }
};
