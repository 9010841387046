import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IRootState } from '@core/store/root.state';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { LoadError } from '@core/store/error/error.actions';
import { UploadService } from '@core/store/upload/upload.service';
import {
  EUploadActions,
  UploadAccountImage,
  UploadAccountImageSuccess,
  UploadCategoryImage,
  UploadCategoryImageSuccess,
  UploadFeaturedImage,
  UploadUserImage,
  UploadUserImageSuccess,
} from '@core/store/upload/upload.actions';
import { UpdateAccount, UpdateUser } from '@core/store/authentication/authentication.actions';
import { UpdateCategory } from '@core/store/catalog/catalog.actions';
import { selectBookingThemeSettings } from '@core/store/settings/settings.selectors';
import { UpdateBookingThemeSettings } from '@core/store/settings/settings.actions';

@Injectable()
export class UploadEffects {
  constructor(
    private actions$: Actions,
    private store: Store<IRootState>,
    private uploadService: UploadService
  ) {}

  public onUploadProfileImage = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadAccountImage>(EUploadActions.UploadAccountImage),
      mergeMap(action =>
        from(this.uploadService.uploadImageWithSignedURL(action.file)).pipe(
          mergeMap(data => [new UpdateAccount({ image_id: data.id }), new UploadAccountImageSuccess()]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUploadFeaturedImage = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadFeaturedImage>(EUploadActions.UploadFeaturedImage),
      withLatestFrom(this.store.select(selectBookingThemeSettings)),
      mergeMap(([action, themeSettings]) =>
        from(this.uploadService.uploadImageWithSignedURL(action.file)).pipe(
          mergeMap(data => [
            new UpdateBookingThemeSettings({ ...themeSettings, highlighted_image_id: data.id, highlighted_image_url: data.url }),
            new UploadAccountImageSuccess(),
          ]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUploadCategoryImage = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadCategoryImage>(EUploadActions.UploadCategoryImage),
      mergeMap(action =>
        from(this.uploadService.uploadImageWithSignedURL(action.file)).pipe(
          mergeMap(data => [new UpdateCategory(action.category_id, { image_id: data.id }), new UploadCategoryImageSuccess()]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUploadUserImage = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadUserImage>(EUploadActions.UploadUserImage),
      mergeMap(action =>
        from(this.uploadService.uploadImageWithSignedURL(action.file)).pipe(
          mergeMap(data => [new UpdateUser(action.user_id, { image_id: data.id }), new UploadUserImageSuccess()]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );
}
