import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { IRootState } from '@core/store/root.state';
import { catchError, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { LoadError } from '@core/store/error/error.actions';
import {
  ActivateFlatpay,
  ActivateFlatpaySuccess,
  CreateFlatpayTerminal,
  CreateFlatpayTerminalSuccess,
  CreatePaymentMethod,
  CreatePaymentMethodSuccess,
  DeactivateFlatpay,
  DeleteFlatpayTerminal,
  DeleteFlatpayTerminalSuccess,
  DeletePaymentMethod,
  DeletePaymentMethodSuccess,
  EditPaymentMethod,
  EditPaymentMethodSuccess,
  EEconomyActions,
  FetchFlatpay,
  FetchFlatpaySuccess,
  FetchFlatpayTerminals,
  FetchFlatpayTerminalsSuccess,
  FetchPaymentMethods,
  FetchPaymentMethodsSuccess,
  FetchReport,
  FetchReportSuccess,
  FetchSaleById,
  FetchSaleByIdSuccess,
  FetchSales,
  FetchSalesSuccess,
} from '@core/store/economy/economy.actions';
import { EconomyService } from '@core/store/economy/economy.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EPaymentMethodType } from '@enums/payment-method-type.enum';
import { ELogLevel } from '@app/shared/components/log-content/enums/log-level.enum';
import { LogContentService } from '@app/shared/components/log-content/services/log-content.service';
import { NotificationService } from '@services/notification/notification.service';
import { ENotification } from '@enums/notification.enum';

@Injectable()
export class EconomyEffects {
  constructor(
    private actions$: Actions,
    private store: Store<IRootState>,
    private economyService: EconomyService,
    private router: Router,
    private route: ActivatedRoute,
    private logContent: LogContentService,
    private notificationService: NotificationService
  ) {}

  public onFetchReport = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchReport>(EEconomyActions.FetchReport),
      mergeMap(action =>
        from(this.economyService.getReport(action.from_date, action.to_date)).pipe(
          mergeMap(data => [new FetchReportSuccess(data)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchSales = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchSales>(EEconomyActions.FetchSales),
      mergeMap(action =>
        from(this.economyService.getSales(action.from_date, action.to_date, action.next_page)).pipe(
          mergeMap(data => [new FetchSalesSuccess(data)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchSaleById = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchSaleById>(EEconomyActions.FetchSaleById),
      mergeMap(action =>
        from(this.economyService.getSaleById(action.id)).pipe(
          mergeMap(data => [new FetchSaleByIdSuccess(data)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchPaymentMethods = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchPaymentMethods>(EEconomyActions.FetchPaymentMethods),
      mergeMap(action =>
        from(this.economyService.getPaymentMethods()).pipe(
          mergeMap(data => [new FetchPaymentMethodsSuccess(data)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCreatePaymentMethod = createEffect(() =>
    this.actions$.pipe(
      ofType<CreatePaymentMethod>(EEconomyActions.CreatePaymentMethod),
      mergeMap(action =>
        from(this.economyService.createPaymentMethod(action.name, action.paymentType)).pipe(
          mergeMap(() => {
            const actions: Action[] = [new CreatePaymentMethodSuccess(action.paymentType), new FetchPaymentMethods()];

            if (action.paymentType === EPaymentMethodType.flatpay) {
              actions.push(new ActivateFlatpay(action.flatpayToken));
            } else {
              this.router.navigate(['portal/settings/payment-methods'], { relativeTo: this.route });
            }

            return actions;
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCreatePaymentMethodSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType<CreatePaymentMethodSuccess>(EEconomyActions.CreatePaymentMethodSuccess),
      mergeMap(action => {
        if (action.paymentType === EPaymentMethodType.flatpay) {
        }

        return [];
      })
    )
  );

  public onEditPaymentMethod = createEffect(() =>
    this.actions$.pipe(
      ofType<EditPaymentMethod>(EEconomyActions.EditPaymentMethod),
      mergeMap(action =>
        from(this.economyService.editPaymentMethod(action.id, action.name, action.paymentType)).pipe(
          mergeMap(() => {
            const actions: Action[] = [new EditPaymentMethodSuccess(), new FetchPaymentMethods()];

            if (action.paymentType === EPaymentMethodType.flatpay) {
              actions.push(new ActivateFlatpay(action.flatpayToken));
            } else {
              this.router.navigate(['portal/settings/payment-methods'], { relativeTo: this.route });
            }

            return actions;
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onDeletePaymentMethods = createEffect(() =>
    this.actions$.pipe(
      ofType<DeletePaymentMethod>(EEconomyActions.DeletePaymentMethod),
      mergeMap(action =>
        from(this.economyService.deletePaymentMethod(action.id)).pipe(
          mergeMap(() => {
            const actions: Action[] = [new DeletePaymentMethodSuccess(), new FetchPaymentMethods()];

            if (action.paymentType === EPaymentMethodType.flatpay) {
              actions.push(new DeactivateFlatpay());
            } else {
              this.router.navigate(['portal/settings/payment-methods'], { relativeTo: this.route });
            }

            return actions;
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchFlatpay = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchFlatpay>(EEconomyActions.FetchFlatpay),
      mergeMap(action =>
        from(this.economyService.getFlatpayIntegration()).pipe(
          mergeMap(flatpay => [new FetchFlatpaySuccess(flatpay)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onActivateFlatpay = createEffect(() =>
    this.actions$.pipe(
      ofType<ActivateFlatpay>(EEconomyActions.ActivateFlatpay),
      mergeMap(action =>
        from(this.economyService.setFlatpayIntegration(action.token)).pipe(
          mergeMap(() => {
            this.logContent.logContent({
              level: ELogLevel.SUCCESS,
              description: 'Flatpay aktiveret',
            });

            return [new ActivateFlatpaySuccess()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onDeactivateFlatpay = createEffect(() =>
    this.actions$.pipe(
      ofType<DeactivateFlatpay>(EEconomyActions.DeactivateFlatpay),
      mergeMap(action =>
        from(this.economyService.setFlatpayIntegration(null)).pipe(
          mergeMap(() => {
            this.logContent.logContent({
              level: ELogLevel.SUCCESS,
              description: 'Flatpay deaktiveret',
            });

            return [new ActivateFlatpaySuccess()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchTerminals = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchFlatpayTerminals>(EEconomyActions.FetchFlatpayTerminals),
      mergeMap(action =>
        from(this.economyService.getFlatpayTerminals()).pipe(
          mergeMap(terminals => [new FetchFlatpayTerminalsSuccess(terminals)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCreateTerminal = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateFlatpayTerminal>(EEconomyActions.CreateFlatpayTerminal),
      mergeMap(action =>
        from(this.economyService.createFlatpayTerminal(action.name, action.externalId, action.shouldPrint)).pipe(
          mergeMap(() => {
            this.logContent.logContent({
              level: ELogLevel.SUCCESS,
              description: 'Terminal oprettet',
            });

            this.notificationService.sendNotification(ENotification.TERMINAL_UPDATED, null);

            return [new CreateFlatpayTerminalSuccess(), new FetchFlatpayTerminals()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onDeleteTerminal = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteFlatpayTerminal>(EEconomyActions.DeleteFlatpayTerminal),
      mergeMap(action =>
        from(this.economyService.deleteFlatpayTerminal(action.id)).pipe(
          mergeMap(() => [new DeleteFlatpayTerminalSuccess(), new FetchFlatpayTerminals()]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );
}
