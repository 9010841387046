import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { IRootState } from '@core/store/root.state';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { forkJoin, from, of } from 'rxjs';
import { LoadError } from '@core/store/error/error.actions';
import {
  AddDiscount,
  AddDiscountSuccess,
  CreateOrder,
  CreateOrderSuccess,
  EOrderActions,
  FetchOrder,
  FetchOrderSuccess,
  UpdateOrderLine,
  UpdateOrderLines,
  UpdateOrderLinesSuccess,
  UpdateOrderLineSuccess,
} from '@core/store/order/order.actions';
import { OrderService } from '@core/store/order/order.service';
import { NotificationService } from '@services/notification/notification.service';
import { ENotification } from '@enums/notification.enum';
import { FetchCalendarBookings, UpdateBooking } from '@core/store/booking/booking.actions';
import { ELogLevel } from '@app/shared/components/log-content/enums/log-level.enum';
import { LogContentService } from '@app/shared/components/log-content/services/log-content.service';

@Injectable()
export class OrderEffects {
  constructor(
    private actions$: Actions,
    private store: Store<IRootState>,
    private orderService: OrderService,
    private notificationService: NotificationService,
    private logService: LogContentService
  ) {}

  public onFetchOrder = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchOrder>(EOrderActions.FetchOrder),
      mergeMap(action =>
        from(this.orderService.getOrderById(action.id)).pipe(
          mergeMap(order => [new FetchOrderSuccess(order)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUpdateOrderLines = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateOrderLines>(EOrderActions.UpdateOrderLines),
      switchMap(action =>
        forkJoin([
          ...action.reqData.addedLines.map(addedLine =>
            from(this.orderService.addToOrder(action.id, addedLine)).pipe(catchError(error => of(new LoadError(error, action))))
          ),
          ...action.reqData.removedLines.map(removedLine =>
            from(this.orderService.removeFromOrder(action.id, removedLine)).pipe(catchError(error => of(new LoadError(error, action))))
          ),
        ]).pipe(
          mergeMap(() => {
            this.notificationService.sendNotification(ENotification.ORDER_UPDATED, null);

            this.logService.logContent({
              level: ELogLevel.SUCCESS,
              description: 'Ordre opdateret',
              actions: [],
            });

            return [new UpdateOrderLinesSuccess(), new FetchOrder(action.id), new FetchCalendarBookings()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onAddDiscount = createEffect(() =>
    this.actions$.pipe(
      ofType<AddDiscount>(EOrderActions.AddDiscount),
      switchMap(action =>
        forkJoin([
          ...action.lines.map(updatedLine =>
            from(this.orderService.patchOrderLine(action.order_id, updatedLine.id, { discount_amount: updatedLine.discount_amount })).pipe(
              catchError(error => of(new LoadError(error, action)))
            )
          ),
        ]).pipe(
          mergeMap(() => {
            this.notificationService.sendNotification(ENotification.ORDER_UPDATED, null);

            this.logService.logContent({
              level: ELogLevel.SUCCESS,
              description: 'Ordre opdateret',
              actions: [],
            });

            return [new AddDiscountSuccess(), new FetchOrder(action.order_id)];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUpdateOrderLine = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateOrderLine>(EOrderActions.UpdateOrderLine),
      mergeMap(action =>
        from(this.orderService.patchOrderLine(action.orderId, action.lineId, action.line)).pipe(
          mergeMap(order => {
            this.logService.logContent({
              level: ELogLevel.SUCCESS,
              description: 'Pris opdateret',
              actions: [],
            });

            return [new UpdateOrderLineSuccess(), new FetchOrder(action.orderId)];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCreateOrder = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateOrder>(EOrderActions.CreateOrder),
      mergeMap(action =>
        from(this.orderService.createOrder(action.reqData)).pipe(
          mergeMap(order => {
            const actions: Action[] = [new CreateOrderSuccess(order)];

            if (action.booking_id) {
              actions.push(new UpdateBooking(action.booking_id, { order_id: order.id }));
            }

            return actions;
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );
}
