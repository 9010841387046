{
  "name": "bodil-portal",
  "version": "0.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host 0.0.0.0",
    "dev": "ng serve --host 0.0.0.0",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "jest",
    "ng-high-memory": "node --max_old_space_size=6000 ./node_modules/@angular/cli/bin/ng",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org bodil-uw --project portal ./dist && sentry-cli sourcemaps upload --org bodil-uw --project portal ./dist"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.1.1",
    "@angular/cdk": "^17.1.1",
    "@angular/common": "^17.1.1",
    "@angular/compiler": "^17.1.1",
    "@angular/core": "^17.1.1",
    "@angular/forms": "^17.1.1",
    "@angular/platform-browser": "^17.1.1",
    "@angular/platform-browser-dynamic": "^17.1.1",
    "@angular/router": "^17.1.1",
    "@angular/service-worker": "^17.1.1",
    "@iplab/ngx-color-picker": "^17.1.2",
    "@ngneat/until-destroy": "^10.0.0",
    "@ngrx/effects": "^17.1.0",
    "@ngrx/router-store": "^17.1.0",
    "@ngrx/schematics": "^17.1.0",
    "@ngrx/store": "^17.1.0",
    "@ngrx/store-devtools": "^17.1.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@sentry/angular-ivy": "^7.98.0",
    "@stripe/stripe-js": "^2.4.0",
    "@taiga-ui/cdk": "^3.63.0",
    "@taiga-ui/core": "^3.63.0",
    "@taiga-ui/icons": "^3.63.0",
    "@taiga-ui/kit": "^3.63.0",
    "@tailwindcss/forms": "^0.5.7",
    "@tailwindcss/typography": "^0.5.10",
    "@tinkoff/ng-dompurify": "^4.0.0",
    "angular-cc-library": "^3.2.0",
    "chart.js": "^4.4.1",
    "chartjs-plugin-datalabels": "^2.2.0",
    "dompurify": "^3.0.8",
    "immer": "^9.0.16",
    "jwt-decode": "^3.1.2",
    "lottie-web": "^5.12.2",
    "ng-recaptcha": "^13.2.1",
    "ng2-charts": "^5.0.4",
    "ngx-infinite-scroll": "^17.0.0",
    "ngx-lottie": "^11.0.2",
    "ngx-stripe": "^17.0.1",
    "pusher-js": "^8.3.0",
    "rxjs": "^7.8.1",
    "tslib": "^2.6.2",
    "webfontloader": "^1.6.28",
    "zone.js": "^0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.1.1",
    "@angular/cli": "^17.1.1",
    "@angular/compiler": "^17.1.1",
    "@angular/compiler-cli": "^17.1.1",
    "@angular/language-service": "^17.1.1",
    "@sentry/cli": "^2.26.0",
    "@types/dompurify": "^3.0.5",
    "@types/jest": "^29.5.11",
    "@types/moment-timezone": "^0.5.30",
    "@types/node": "^16.14.2",
    "@types/webfontloader": "^1.6.38",
    "@typescript-eslint/eslint-plugin": "5.3.0",
    "@typescript-eslint/parser": "5.3.0",
    "autoprefixer": "^10.4.11",
    "eslint": "^7.6.0",
    "eslint-plugin-import": "2.22.1",
    "eslint-plugin-jsdoc": "30.7.6",
    "eslint-plugin-prefer-arrow": "1.2.2",
    "jest": "^29.7.0",
    "jest-preset-angular": "^14.0.0",
    "prettier": "^3.2.4",
    "prettier-plugin-tailwindcss": "^0.5.11",
    "tailwindcss": "^3.4.1",
    "ts-node": "~10.9.2",
    "tslint": "~6.1.3",
    "tslint-config-prettier": "^1.18.0",
    "typescript": "~5.3.3"
  }
}
