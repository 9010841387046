import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IRootState } from '@core/store/root.state';
import { catchError, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { LoadError } from '@core/store/error/error.actions';
import {
  ChangeBookingHours,
  CreateSchedule,
  DeleteSchedule,
  DeleteScheduleSuccess,
  ESettingsActions,
  FetchBookingHours,
  FetchBookingHoursSuccess,
  FetchBookingThemeSettings,
  FetchBookingThemeSettingsSuccess,
  FetchSchedule,
  FetchScheduleSuccess,
  FetchSMSPrice,
  FetchSMSPriceSuccess,
  FetchSMSSettings,
  FetchSMSSettingsSuccess,
  FetchSMSTemplates,
  FetchSMSTemplatesSuccess,
  PatchSchedule,
  PatchScheduleSuccess,
  UpdateBookingThemeSettings,
  UpdateBookingThemeSettingsSuccess,
  UpdateSMSSettings,
  UpdateSMSSettingsSuccess,
  UpdateSMSTemplate,
  UpdateSMSTemplateSuccess,
} from '@core/store/settings/settings.actions';
import { SettingsService } from '@core/store/settings/settings.service';
import { LogContentService } from '@app/shared/components/log-content/services/log-content.service';
import { ELogLevel } from '@app/shared/components/log-content/enums/log-level.enum';
import { NotificationService } from '@services/notification/notification.service';
import { ENotification } from '@enums/notification.enum';

@Injectable()
export class SettingsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<IRootState>,
    private settingsService: SettingsService,
    private logContent: LogContentService,
    private notificationService: NotificationService
  ) {}

  public onFetchBookingHours = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchBookingHours>(ESettingsActions.FetchBookingHours),
      mergeMap(action =>
        from(this.settingsService.getBookingHours()).pipe(
          mergeMap(hours => [new FetchBookingHoursSuccess(hours)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onChangeBookingHours = createEffect(() =>
    this.actions$.pipe(
      ofType<ChangeBookingHours>(ESettingsActions.ChangeBookingHours),
      mergeMap(action =>
        from(this.settingsService.postBookingHours(action.toCreate)).pipe(
          mergeMap(() =>
            from(this.settingsService.patchBookingHours(action.toUpdate)).pipe(
              mergeMap(() =>
                from(this.settingsService.deleteBookingHours(action.toDelete)).pipe(
                  mergeMap(() => [new FetchBookingHours()]),
                  catchError(error => of(new LoadError(error, action)))
                )
              ),
              catchError(error => of(new LoadError(error, action)))
            )
          ),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchSchedule = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchSchedule>(ESettingsActions.FetchSchedule),
      mergeMap(action =>
        from(this.settingsService.getSchedule(action.from_date, action.to_date)).pipe(
          mergeMap(schedule => [new FetchScheduleSuccess(schedule)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCreateSchedule = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateSchedule>(ESettingsActions.CreateSchedule),
      mergeMap(action =>
        from(this.settingsService.postSchedule(action.reqData)).pipe(
          mergeMap(schedule => {
            this.notificationService.sendNotification(ENotification.SCHEDULE_UPDATED, null);

            this.logContent.logContent({
              level: ELogLevel.SUCCESS,
              description: 'Vagtplan oprettet!',
            });

            return [new PatchScheduleSuccess(), new FetchSchedule(action.weekStart, action.weekEnd)];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onPatchSchedule = createEffect(() =>
    this.actions$.pipe(
      ofType<PatchSchedule>(ESettingsActions.PatchSchedule),
      mergeMap(action =>
        from(this.settingsService.patchSchedule(action.id, action.reqData)).pipe(
          mergeMap(schedule => {
            this.notificationService.sendNotification(ENotification.SCHEDULE_UPDATED, null);

            this.logContent.logContent({
              level: ELogLevel.SUCCESS,
              description: 'Vagtplan opdateret',
            });

            return [new PatchScheduleSuccess(), new FetchSchedule(action.weekStart, action.weekEnd)];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onDeleteSchedule = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteSchedule>(ESettingsActions.DeleteSchedule),
      mergeMap(action =>
        from(this.settingsService.deleteSchedule(action.id)).pipe(
          mergeMap(schedule => {
            this.notificationService.sendNotification(ENotification.SCHEDULE_UPDATED, null);

            this.logContent.logContent({
              level: ELogLevel.SUCCESS,
              description: 'Vagtplan slettet',
            });

            return [new DeleteScheduleSuccess(), new FetchSchedule(action.weekStart, action.weekEnd)];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchSMSSettings = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchSMSSettings>(ESettingsActions.FetchSMSSettings),
      mergeMap(action =>
        from(this.settingsService.getSMSSettings()).pipe(
          mergeMap(settings => [new FetchSMSSettingsSuccess(settings)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchBookingThemeSettings = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchBookingThemeSettings>(ESettingsActions.FetchBookingThemeSettings),
      mergeMap(action =>
        from(this.settingsService.getBookingThemeSettings()).pipe(
          mergeMap(settings => [new FetchBookingThemeSettingsSuccess(settings)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUpdateBookingThemeSettings = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateBookingThemeSettings>(ESettingsActions.UpdateBookingThemeSettings),
      mergeMap(action =>
        from(this.settingsService.patchBookingThemeSettings(action.settings)).pipe(
          mergeMap(data => [new UpdateBookingThemeSettingsSuccess()]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchSMSPrice = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchSMSPrice>(ESettingsActions.FetchSMSPrice),
      mergeMap(action =>
        from(this.settingsService.getSMSPrice()).pipe(
          mergeMap(price => [new FetchSMSPriceSuccess()]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUpdateSMSSettings = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateSMSSettings>(ESettingsActions.UpdateSMSSettings),
      mergeMap(action =>
        from(this.settingsService.patchSMSSettings(action.reqData)).pipe(
          mergeMap(() => [new UpdateSMSSettingsSuccess(), new FetchSMSSettings()]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchSMSTemplates = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchSMSTemplates>(ESettingsActions.FetchSMSTemplates),
      mergeMap(action =>
        from(this.settingsService.getSMSTemplates()).pipe(
          mergeMap(templates => [new FetchSMSTemplatesSuccess(templates)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUpdateSMSTemplates = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateSMSTemplate>(ESettingsActions.UpdateSMSTemplate),
      mergeMap(action =>
        from(this.settingsService.patchSMSTemplates(action.id, action.reqData)).pipe(
          mergeMap(() => {
            this.logContent.logContent({
              level: ELogLevel.SUCCESS,
              description: 'SMS opdateret',
            });

            return [new UpdateSMSTemplateSuccess(), new FetchSMSTemplates()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );
}
