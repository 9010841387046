import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { IRootState } from '@app/core/store/root.state';
import { routerReducer } from '@ngrx/router-store';
import { hydrationMetaReducer } from '@app/core/store/hydration/hydration.reducer';
import { authenticationReducers } from '@app/core/store/authentication/authentication.reducers';
import { navigationReducers } from '@core/store/navigation/navigation.reducers';
import { bookingReducers } from '@core/store/booking/booking.reducers';
import { catalogReducers } from '@core/store/catalog/catalog.reducers';
import { uploadReducers } from '@core/store/upload/upload.reducers';
import { orderReducers } from '@core/store/order/order.reducers';
import { paymentReducers } from '@core/store/payment/payment.reducers';
import { settingsReducers } from '@core/store/settings/settings.reducers';
import { customerReducers } from '@core/store/customer/customer.reducers';
import { economyReducers } from '@core/store/economy/economy.reducers';
import { statisticsReducers } from '@core/store/statistics/statistics.reducers';
import { voucherReducers } from '@core/store/voucher/voucher.reducers';
import { billingReducers } from '@core/store/billing/billing.reducers';
import { tillsReducers } from '@core/store/tills/tills.reducers';

export const metaReducers: MetaReducer[] = [hydrationMetaReducer];

export const rootReducers: ActionReducerMap<IRootState, any> = {
  router: routerReducer,
  navigation: navigationReducers,
  authentication: authenticationReducers,
  booking: bookingReducers,
  catalog: catalogReducers,
  upload: uploadReducers,
  order: orderReducers,
  payment: paymentReducers,
  settings: settingsReducers,
  customer: customerReducers,
  economy: economyReducers,
  statistics: statisticsReducers,
  voucher: voucherReducers,
  billing: billingReducers,
  tills: tillsReducers,
};
