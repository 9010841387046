import { TuiLanguageKit } from '@taiga-ui/i18n/interfaces';

export const TUI_DANISH_LANGUAGE_KIT: TuiLanguageKit = {
  cancel: `Annuller`,
  done: `Færdig`,
  more: `Mere`,
  otherDate: `Anden dato...`,
  showAll: `Vis alle`,
  hide: `Skjul`,
  mobileCalendarTexts: [`Vælg dag`, `Vælg periode`, `Vælg dage`],
  range: [`fra`, `til`],
  countTexts: [`Plus`, `Minus`],
  time: {
    'HH:MM': `HH:MM`,
    'HH:MM:SS': `HH:MM:SS`,
    'HH:MM:SS.MSS': `HH:MM:SS.MSS`,
  },
  dateTexts: {
    DMY: `dd.mm.yyyy`,
    MDY: `mm.dd.yyyy`,
    YMD: `yyyy.mm.dd`,
  },
  digitalInformationUnits: [`B`, `KB`, `MB`],
  passwordTexts: [`Vis adgangskode`, `Skjul adgangskode`],
  copyTexts: [`Kopiér`, `Kopieret`],
  shortCalendarMonths: [`Jan`, `Feb`, `Mar`, `Apr`, `Maj`, `Jun`, `Jul`, `Aug`, `Sep`, `Okt`, `Nov`, `Dec`],
  pagination: [`Forrige side`, `Næste side`],
  fileTexts: {
    loadingError: `Fejl ved upload`,
    preview: `Forhåndsvisning`,
    remove: `Fjern`,
  },
  inputFileTexts: {
    defaultLabelSingle: `eller slip\u00A0den\u00A0her`,
    defaultLabelMultiple: `eller slip\u00A0dem\u00A0her`,
    defaultLinkSingle: `Vælg en fil`,
    defaultLinkMultiple: `Vælg filer`,
    maxSizeRejectionReason: `Filen er for stor `,
    formatRejectionReason: `Forkert filtype`,
    drop: `Træk filen herhen`,
    dropMultiple: `Træk filer herhen`,
  },
  multiSelectTexts: {
    all: `Vælg alle`,
    none: `Vælg ingen`,
  },
  prompt: {
    yes: `Ja`,
    no: `Nej`,
  },
};
